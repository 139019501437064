declare global {
  interface Window {
    Iugu?: any;
  }
}

export interface CreditCardIn{
  number: string,
  verification_value: string,
  first_name: string,
  last_name: string,
  month: string,
  year: string
}

export interface CreditCardOut{
  bin: string,
  year: string,
  month: string,
  brand: string,
  holder_name: string,
  display_number: string,
}

export interface IuguToken{
  id: string,
  method: string,
  extra_info: CreditCardOut,
  test: boolean
}

class IuguService{
  public static getIuguCardToken(credit_card:CreditCardIn): Promise<IuguToken>{
    return new Promise<IuguToken>((resolve, reject)=>{
      const iugu = window.Iugu
      iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)
      iugu.setTestMode(false);
      iugu.setup()

      iugu.createPaymentToken(credit_card, function(response) {
        if (response.errors) {
          reject("Iugu token error")
        } else {
          resolve(response)
        }
      });
    })
  }
}

export default IuguService