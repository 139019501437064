<script setup lang="ts">

</script>

<template>
  <li class="fs-6 my-4 cursor-pointer text-hover-success">
    <i class="bi bi-check2-square text-success fs-6 me-2"></i>
    <span class="text-gray-700 text-hover-gray-900 ">
      <slot />
    </span>
  </li>
</template>

<style scoped lang="scss">

</style>